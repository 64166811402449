<template>
  <div class="wrap">
    <div class="item1" >
      <p style="font-size: 18px;margin-bottom:30px">关注我们</p>
      <img src="@/assets/images/QR.jpg" alt="" />
      <p style="margin-top:6px;">关注微信公众号</p>
    </div>
    <div class="item2" >
      <p style="font-size: 18px;margin-bottom:46px;">联系方式</p>
      <p>公司地址：南宁市青秀区金洲路11号金旺角商住楼B栋B1703室</p>
      <p>联系电话：4001330365</p>
      <p>邮箱地址：guangxi@zhonghuicn.com</p>
      <p>如果您对网站有任何建议，欢迎给我们发送邮件！</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.wrap{
  display: flex;
  justify-content: center;
}
.item1{
display: flex;
flex-direction: column;
align-items: center;

}
.item1>img{
  width: 135px;
    height: 135px;
    padding: 12px;
    border: 1px solid #ebeef5;
}
.item2{
margin-right:10px;
margin-left: 60px;
}
</style>