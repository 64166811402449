<template>
  <div class='bannerWrap'>
    <img src="../assets/images/slider-img-1.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.bannerWrap{
  margin-top: 20px;
}
.bannerWrap img{
border-radius: 8px;
}
</style>