<template>
  <el-row class="about" style="margin-top: 20px">
    <el-col :span="4" style="margin-bottom: 20px">
      <el-menu
        default-active="1"
        class="el-menu-vertical-demo"
        active-text-color="#67c23a"
        @select="handleSelect"
      >
        <el-menu-item index="1">
          <i class="el-icon-location"></i>
          <span slot="title">公司介绍</span>
        </el-menu-item>
        <el-menu-item index="2">
          <i class="el-icon-menu"></i>
          <span slot="title">分支机构</span>
        </el-menu-item>
        <el-menu-item index="3">
          <i class="el-icon-document"></i>
          <span slot="title">隐私声明</span>
        </el-menu-item>
        <el-menu-item index="4">
          <i class="el-icon-setting"></i>
          <span slot="title">联系我们</span>
        </el-menu-item>
      </el-menu>
    </el-col>
    <el-col :span="20" style="margin-bottom: 20px">
      <el-card class="box-card" shadow="never" style="margin-left: 20px">
        <div slot="header" class="head">
          <span>{{ computedCardTitle }}</span>
        </div>
        <div class="content">
          <Content1 v-if="computedCardContent == 1" />
          <Content3 v-if="computedCardContent == 3" />
          <Content4 v-if="computedCardContent == 4" />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>
import Content1 from "@/components/about/Content1.vue";
import Content3 from "@/components/about/Content3.vue";
import Content4 from "@/components/about/Content4.vue";
export default {
  components: {
    Content1,
    Content3,
    Content4,
  },
  data() {
    return {
      activeNum: "1",
    };
  },
  mounted() {
    this.activeNum = this.$route.query.activeNum
      ? this.$route.query.activeNum
      : "1";
  },
  methods: {
    handleSelect(key, v) {
      if (key == 2) {
        window.location.href =
          "https://www.zhonghuicn.com/index.php/Home/Contact";
        return;
      }
      this.activeNum = key;
    },
  },
  computed: {
    computedCardTitle() {
      if (this.activeNum == 1) {
        return "公司介绍";
      } else if (this.activeNum == 2) {
        return "分支机构";
      } else if (this.activeNum == 3) {
        return "隐私声明";
      } else if (this.activeNum == 4) {
        return "联系我们";
      }
    },
    computedCardContent() {
      if (this.activeNum == 1) {
        return 1;
      } else if (this.activeNum == 2) {
        return 2;
      } else if (this.activeNum == 3) {
        return 3;
      } else if (this.activeNum == 4) {
        return 4;
      }
    },
  },
};
</script>
<style scoped>
.about {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.box-card {
  text-align: left;
}
.head {
  font-size: 16px;
  color: #67c23a;
  font-weight: 400;
}
.head > span {
  border-left: 3px solid #67c23a;
  padding-left: 10px;
}
.content {
  margin-bottom: 30px;
}
</style>
