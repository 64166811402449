<template>
  <div class="wrap">
    <dt>（1）</dt>
    <dd>
      本网站的用户在注册或投保时提供的个人资料，如：姓名、出生日期、电话、电子邮箱、身份证号码、银行卡号等，我们郑重承诺在未经用户同意的情况下，绝对不会将用户的任何资料以任何方式泄露给任何第三方；
    </dd>
    <dt>（2）</dt>
    <dd>
      我们不会向第三方透露您的个人信息，但以下情况除外：<br />
      ①事先已经获得您的授权，您同意让第三方共享资料；<br />
      ②只有您提供个人相关资料，才能提供您所需的产品和服务；<br />
      ③根据有关的法律法规或相关政府主管部门的要求；<br />
      ④我们需要向代表我们提供产品或服务的保险公司提供资料，如您通过本站购买保险产品，您的个人信息将会传递给您所选择的保险公司。<br />
    </dd>
    <dt>（3）</dt>
    <dd>
      若有下列情况之一者，本网站将不承担任何法律责任：<br />
      ①由于用户将个人密码告知他人或与他人共享注册账户，由此导致的任何个人资料泄露；<br />
      ②任何由于黑客攻击、计算机病毒侵入或发作、政府管制等造成的暂时性关闭，使网络无法正常运行而造成的个人资料泄露、丢失、被盗用或被窜改等；<br />
      ③由于其他不可抗力因素而引起的任何后果。<br />
      我方有相应的安全措施来确保我们掌握的信息不丢失、不被滥用。这些安全措施包括向其它服务器备份数据和对用户密码加密。尽管我们坚持按照行业标准实践来保护您的个人信息和机密数据，我们并不能确保这些信息和数据不会以本隐私声明所述外的其他方式被泄露。
    </dd>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.wrap {
  width: 930px;
  display: flex;
  flex-wrap: wrap;
}
.wrap > dt {
  width: 30px;
}
.wrap > dd {
  width: 870px;
  margin-left: 10px;
  line-height: 24px;
  margin-bottom: 20px;
}
</style>