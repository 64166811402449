<template>
  <div>
    <BannerImg />
    <ProductList />
  </div>
</template>

<script>
import BannerImg from "@/components/BannerImg.vue";
import ProductList from "@/components/ProductList.vue";
export default {
  components: {
    BannerImg,
    ProductList,
  }
};
</script>

<style scoped>
</style>