<template>
  <div>
    <ProductList />
  </div>
</template>

<script>
import ProductList from "@/components/ProductList.vue";
export default {
  components: {
    ProductList,
  },
};
</script>

<style scoped>
</style>