<template>
  <div>
  <About  />
  </div>
</template>

<script>
import About from "@/components/about/About.vue";
export default {
  components: {
    About,
  },
}
</script>

<style scoped>

</style>
