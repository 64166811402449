<template>
  <div style="line-height: 24px">
    <p style="text-indent: 2em">
      中惠保险经纪有限公司是经中国保险监督管理委员会批准开业的全国性、综合性保险经纪公司。公司的注册资本金为人民币5001万元，业务经营区域为：中华人民共和国行政辖区（不含港、澳、台）。
    </p>
    <p style="text-indent: 2em">
      中惠总部设在浙江杭州，公司的管理中心、技术中心设立在杭州，公司的市场中心设立在广州。公司成立以来，以
      “诚信、专业、有社会责任感的最具有竞争力的上市公司”为发展目标，始终贯彻“诚信、合作、创新、卓越”的企业精神，已经为上百家各类大中型企业客户提供了保险经纪服务，保险经纪业务覆盖包括国家机关、政府采购、医疗卫生、教育行业、通讯行业、金融机构、石油化工、电力能源、水利水电、交通运输、工业制造、冶金矿产、工程建筑等社会发展和国民经济建设主要领域，尤其在通讯行业、政府采购、医疗卫生及国家重大工程等方面的保险经纪业务占据国内领先地位。
    </p>
    <p style="text-indent: 2em">
      公司全体员工在董事会的正确领导下，在广大客户的大力支持下，合法合规，规范运作，稳健经营，积极探索，在客户服务、业务规模、机构发展、员工队伍建设等方面不断进取，争创国内一流，国际领先的专业化、国际化的保险经纪公司。
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
</style>

