<template>
  <div class="wrap">
    <el-card
      class="box-card"
      shadow="hover"
      v-for="(item, i) in productList"
      :key="i"
    >
      <div slot="header" class="title">
        <span>{{ item.title }}</span>
      </div>
      <div class="content">
        <div class="item1">
          <img
            v-if="item.product_code"
            :src="require(`../assets/images/${item.product_code}.png`)"
            alt=""
            width="247px"
            height="186px"
          />
        </div>
        <dl class="item2">
          <dt>保障范围:</dt>
          <dd>{{ item.insurance_scope }}</dd>
          <dt>适用企业:</dt>
          <dd>{{ item.for_enterprise }}</dd>
          <dt>保险期间:</dt>
          <dd>{{ item.insurance_term }}</dd>
          <dt>保单形式:</dt>
          <dd>{{ item.insurance_polic_type }}</dd>
          <dt>保险责任:</dt>
          <dd>{{ item.insurance_duty }}</dd>
        </dl>
        <div class="item3">
          <el-button
            type="success"
            style="width: 162px"
            @click="toLink(item.product_code, item.industry_code)"
            >立即投保</el-button
          >
          <el-button
            type="success"
            plain
            style="width: 162px; margin-left: 0; margin-top: 20px"
            @click="jumpto(item.product_code, item.industry_code)"
            >查看详情</el-button
          >
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productList: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    toLink(id, industryCode) {
      if (this.$store.state.token) {
        if (industryCode) {
          if (this.is_attestation == 3) {
            this.$message({
              message: "正在认证中,还不能投保",
              type: "error",
            });
          } else if (this.is_attestation == 2) {
            this.$message({
              message: "没有认证,还不能投保",
              type: "error",
            });
          } else {
            this.$router.push({
              path: "/product/clause",
              query: {
                product_code: id,
              },
            });
          }
        } else {
          this.$message({
            message: "只能投保同一类型险种",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "需要登陆后才能操作",
          type: "error",
        });
      }
    },
    jumpto(id, industryCode) {
      this.$router.push({
        path: "/product/detail",
        query: { product_code: id, industry_code: industryCode },
      });
    },
    getData() {
      if (this.$store.state.token) {
        this.axios({
          url: "/api/v1/user/userDetails",
          method: "post",
          data: {
            user_code: this.$store.state.userCode,
          },
        }).then((res) => {
          this.is_attestation = res.data.result.is_attestation;
          this.axios
            .get(
              `/api/v1/prod/prodList?industry_code=${res.data.result.industry}`
            )
            .then((res) => {
              if (res.data.code > 0) {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
              } else {
                this.productList = res.data.result;
              }
            });
        });
      } else {
        this.axios.get(`/api/v1/prod/prodList?industry_code=`).then((res) => {
          if (res.data.code > 0) {
            this.$message({
              message: res.msg,
              type: "error",
            });
          } else {
            this.productList = res.data.result;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.wrap {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 1200px;
  margin-bottom: 20px;
}
.box-card {
  margin-bottom: 10px;
}
/* title */
.title {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
}
.content {
  display: flex;

  /* justify-content: space-between;
  align-items: center; */
}
.item1 {
  width: 247px;
  margin-right: 12px;
}
.item1 > img {
  border-radius: 12px;
}
.item2 {
  width: 660px;
  display: flex;
  flex-wrap: wrap;
}
.item2 > dt {
  width: 80px;
}
.item2 > dd {
  text-align: left;
  margin-left: 5px;
  width: 500px;
  color: #999;
}
.item3 {
  width: 230px;
  border-left: 1px solid #edecec;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>